import React from 'react'

function Insta() {
  return (
    <div>
<section class="gallery">
<h2 className="title_insta">Nosso Instagram</h2>
  <div class="instagram-feed">
    <iframe 
      src="https://www.instagram.com/lilica.patisserie/embed" 
      width="100%" 
      height="600" 
      frameborder="0" 
      scrolling="no">
    </iframe>
  </div>
</section>
     
    </div>
  )
}

export default Insta
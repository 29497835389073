import { useEffect } from "react";

export default function Carousel_Testemunhos() {
  useEffect(() => {
    // Verifica se Swiper foi carregado no window
    if (window.Swiper) {
      new window.Swiper(".swiper-2", {
        spaceBetween: 30,
        slidesPerView: 1,
        simulateTouch: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next-2",
          prevEl: ".swiper-button-prev-2",
        },
        pagination: { el: ".swiper-pagination-2", clickable: true },
        breakpoints: {
            768: { // Para telas maiores que 768px
              slidesPerView: 2, // Mostra 2 slides
              spaceBetween: 20
            },
            1024: { // Para telas maiores que 1024px
              slidesPerView: 3, // Mostra 3 slides
              spaceBetween: 30
            }
          }
      });
    }
}, []);

  return (
    <>
    <div class="swiper swiper-2 mySwiper">
    <div class="swiper-wrapper">

      <div class="swiper-slide swiper-slide-2">
      
      <div class="testimonial">
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="testimonial-text">
        Excelente, fui muito bem atendida, encomendei 700 bem casados, que ficaram muito bonitos, bem embalados e deliciosos. Foi para o casamento da minha sobrinha/neta e os bem casados foram muito elogiados.afirmei à empresa que, com certeza, vou recomenda-los.
        </p>
        <div class="testimonial-author">
            <img src="./images/Testemunhos/ana_piffer.png" alt="ana-piffer"/>
            <div class="author-info">
                <strong>Ana Piffer</strong>
            </div>
        </div>
    </div>

      </div>

      <div class="swiper-slide swiper-slide-2">
      
      <div class="testimonial">
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="testimonial-text">
        Que experiência incrível! Gente, não tem como se arrepender quando se investe em qualidade. Rodei São Paulo para conhecê-los e valeu cada segundo. Que capricho, que atendimento e que doce PERFEITO. Só confiem. Ganharam uma cliente e fã.
        </p>
        <div class="testimonial-author">
            <img src="./images/Testemunhos/Amanda-Alves.png" alt="Amanda Alves"/>
            <div class="author-info">
                <strong>Amanda Alves</strong>
            </div>
        </div>
    </div>

      </div>
      
      <div class="swiper-slide swiper-slide-2">
      
      <div class="testimonial">
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="testimonial-text">
        Super atenciosos desde o primeiro contato… tiraram todas as minha dúvidas e orçaram de acordo com o que precisava… os bem casados são mavilhosos,, molhadinhos e um sabor surreal… super recomendo
        E nas próximas festas com certeza irei contata-los
        </p>
        <div class="testimonial-author">
            <img src="./images/Testemunhos/ThaisS.S.Rigoleto.png" alt="Thais S. S. Rigoleto"/>
            <div class="author-info">
                <strong>Thais S. S. Rigoleto</strong>
            </div>
        </div>
    </div>

      </div>

      <div class="swiper-slide swiper-slide-2">
      
      <div class="testimonial">
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="testimonial-text">
        Precisei de bem casados de última hora. Fui super bem atendida desde o primeiro contato e quando peguei os doces foi uma experiência incrível. Capricho na embalagem e no sabor, doce na medida certa e massa molhadinha. Recomendo fortemente.
        </p>
        <div class="testimonial-author">
            <img src="./images/Testemunhos/GIOVANAMORAES.png" alt="GIOVANAMORAES"/>
            <div class="author-info">
                <strong>GIOVANA MORAES</strong>
            </div>
        </div>
    </div>

      </div>
      <div class="swiper-slide swiper-slide-2">
      
      <div class="testimonial">
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="testimonial-text">
        Fiz os doces do meu casamento com eles, o bem casado e super recomendo! Massa muito macia, não cheira ovo e o doce de leite é muito bom!! Nota mil
        </p>
        <div class="testimonial-author">
            <img src="./images/Testemunhos/MarianaVagula.png" alt="MarianaVagula"/>
            <div class="author-info">
                <strong>Mariana Vagula</strong>
            </div>
        </div>
    </div>

      </div>
      <div class="swiper-slide swiper-slide-2">
      
      <div class="testimonial">
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="testimonial-text">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
            Adipisci eum, aut nisi delectus quis doloremque reiciendis 
            unde corporis quae! Tempore, neque dolore expedita saepe adipisci 
            cumque repellendus enim iste libero?
        </p>
        <div class="testimonial-author">
            <img src="./images/Testemunhos/MariaKarolineDeSousaAlencar.png" alt="MariaKarolineDeSousaAlencar"/>
            <div class="author-info">
                <strong>Maria Karoline De Sousa Alencar</strong>
            </div>
        </div>
    </div>

      </div>
      <div class="swiper-slide swiper-slide-2">
      
      <div class="testimonial">
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="testimonial-text">
        Ótimo! Preço justo e qualidade. Atenderam rapidamente e foram muito pontuais na entrega.
        </p>
        <div class="testimonial-author">
            <img src="./images/Testemunhos/R.Esteves.png" alt="R.Esteves"/>
            <div class="author-info">
                <strong>R. Esteves</strong>
            </div>
        </div>
    </div>

      </div>

    </div>
   
    <div class="swiper-button-next swiper-button-next-2"></div>
    <div class="swiper-button-prev swiper-button-prev-2"></div>

    <div class="swiper-pagination swiper-pagination-2"></div>
  </div>

    </>
  );
}

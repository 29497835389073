import { useEffect } from "react";
import Header from "./Components/Header";
import Carousel_Services from "./Components/Carousel_Services";
import Carousel_Testemunhos from "./Components/Carousel_Testemunhos";
import Footer from "./Components/Footer";
import WhatsApp from "./Components/Whatsapp";
import Popup from "./Components/Popup";
import Insta from "./Components/Instagram";

export default function App() {
  useEffect(() => {
    
    if (window.Swiper) {
      new window.Swiper(".swiper-0", {
        spaceBetween: 30,
        slidesPerView: 1,
        simulateTouch: true,
        effect: "fade",
        fadeEffect: { crossFade: true },
        speed: 800,
        navigation: {
          nextEl: ".swiper-button-next-0",
          prevEl: ".swiper-button-prev-0",
        },
        pagination: { el: ".swiper-pagination-0", clickable: true },
        autoplay: { delay: 2000, disableOnInteraction: false },
        loop: true,
      });
    }
}, []);

  return (
    <>
    <Header />
    <WhatsApp />
    <Popup />
     
      {/* Primeira Dobra */}
      {/* Swiper */}
  <div class="swiper swiper-0 mySwiper">
    <div class="swiper-wrapper">
      
      <div class="swiper-slide">
      <section className="hero_section-0 hero_section">
        <div className="hero_content">
          <h1 className="title_h1">O doce perfeito para momentos inesquecíveis</h1>
          <div>
          <a href="#servicos" rel="noopener noreferrer" className="btn">Saiba Mais</a>
          </div>
          
        </div>
      </section>
      </div>
      
      <div class="swiper-slide">
      <section className="hero_section-1 hero_section">
        <div className="hero_content">
          <h1 className="title_h1">Trazendo a tradição para o seu evento!</h1>
          <div>
          <a href="#servicos" rel="noopener noreferrer" className="btn">Saiba Mais</a>
          </div>
          
        </div>
      </section>
      </div>
      <div class="swiper-slide">
      <section className="hero_section-2 hero_section">
        <div className="hero_content">
          <h1 className="title_h1">Bem Casados</h1>
          <div>
          <a href="#servicos" rel="noopener noreferrer" className="btn">Saiba Mais</a>
          </div>
          
        </div>
      </section>
      </div>
      
    </div>
    <div class="swiper-button-next swiper-button-next-0"></div>
    <div class="swiper-button-prev swiper-button-prev-0"></div>
    <div class="swiper-pagination swiper-pagination-0"></div>
  </div>

  {/* Dobra 2 Serviços */}
  <div id="servicos" className="services">
<div className="content_title">
<h2 className="title_h2">SERVIÇOS</h2>
<h2 className="principal_h2">O QUE FAZEMOS</h2>
<h2 className="titulo_fundo">O QUE FAZEMOS</h2>
</div>
<Carousel_Services />
<div className="btn_service">
<a href="https://www.lilicapatisserie.com.br/solicitar-orcamento" className="btn">Faça já seu Orçamento!</a>
</div>
  </div>

  {/* Dobra 3 Sobre nos */}
  <div id="sobre" className="sobre">
  <div className="content_title">
<h2 className="title_h2">SOBRE</h2>
<h2 className="principal_h2">QUEM SOMOS</h2>
<h2 className="titulo_fundo-1">QUEM SOMOS</h2>
</div>
<div className="content_sobre">
  <div className="content_sobre_div">
    <p className="p_sobre">A <strong>Lilica Patisserie</strong> vai além de uma simples confeitaria — ela carrega histórias, memórias e a doçura dos momentos especiais. Sabemos que as pessoas que amamos e as celebrações que vivemos são únicas, e é isso que torna cada doce tão especial.</p>
    <br />
    <p className="p_sobre">Nossa jornada começou com uma receita de família, passada de geração em geração, até se transformar no que somos hoje. Através das nossas delícias, entregamos mais do que sabor: compartilhamos tradição, amor e afeto, tornando cada celebração inesquecível.</p>

  </div>
  <div className="content_sobre_div">
    <p className="p_sobre">Com dedicação e aperfeiçoamento contínuo, seguimos crescendo e encantando mais pessoas. E, assim como nossas receitas, nosso legado também será passado adiante, porque aqui, os sabores são <strong>eternos, geracionais e cheios de emoção.</strong></p>
    <br />
    <p className="p_sobre">Cada doce que produzimos é feito com dedicação e cuidado, respeitando técnicas artesanais que garantem texturas delicadas e um sabor inesquecível. Utilizamos ingredientes selecionados para criar experiências que encantam ao primeiro toque e permanecem na memória. Mais do que doces, entregamos emoções em forma de sabor, tornando cada momento ainda mais especial.</p>
  </div>
</div>
<h1 class="assinatura">Lilica Patisserie</h1>
  </div>

  {/* Dobra 4 Feedbacks */}
  <div id="feedback" className="feedback">
  <div className="content_title">
<h2 className="title_h2">FEEDBACK</h2>
<h2 className="principal_h2">JÁ APROVARAM</h2>
<h2 className="titulo_fundo-2">JÁ APROVARAM</h2>
</div>

<Carousel_Testemunhos />

  </div>

  {/* Dobra5 Imagem */}
  <div className="bg_image_5"></div>

  {/* Dobra 6 Vantagens */}
  <div id="vantagens" className="vantagens">
  <div className="content_title">
<h2 className="title_h2">MÉTODO</h2>
<h2 className="principal_h2">VANTAGENS</h2>
<h2 className="titulo_fundo-3">VANTAGENS</h2>
</div>

<div className="card_tagens">
<div class="elemento">
<i class="fa fa-heart"></i>
    <h2>Qualidade</h2>
    <p>Nosso compromisso é transformar momentos especiais em memórias doces e inesquecíveis, com muito carinho e dedicação em cada detalhe.</p>
</div>
<div class="elemento">
<i class="fas fa-cake"></i>
    <h2>100% Artesanal</h2>
    <p>Nossos produtos são artesanais feitos com carinho e atenção aos detalhes, garantindo qualidade superior e sabor único.</p>
</div>
<div class="elemento">
<i class="fas fa-brush"></i>
    <h2>Personalização</h2>
    <p>Cada detalhe faz a diferença! Nossos produtos são personalizados especialmente para você, atendendo ao seu gosto e às suas preferências.</p>
</div>
</div>
<div className="btn_service-2">
<a href="https://www.lilicapatisserie.com.br/solicitar-orcamento" className="btn">Viva essa experiência!</a>
</div>
  </div>

  {/* Dobra 7 Galery Image */}
  <Insta />
  
  {/* Dobra 8 Contato */}
  <Footer />
    </>
  );
}

import { useState, useEffect } from "react";

export default function Popup() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Exibir popup automaticamente após 2 segundos
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`popup-overlay ${showPopup ? "show" : ""}`}>
      <div className={`popup ${showPopup ? "show" : ""}`}>
        <button className="close-btn" onClick={() => setShowPopup(false)}>
          ×
        </button>
        <h2>Está procurando Bem Casados?</h2>
        <p>Peça já um orçamento personalizado, e prove nossa degustação!</p>
        <a href="https://www.lilicapatisserie.com.br/solicitar-orcamento" className="access-btn">
          Pedir Cotação
        </a>
      </div>
    </div>
  );
}

import React from 'react'

function WhatsApp() {
  return (
    <div>
<a href="https://www.lilicapatisserie.com.br/solicitar-orcamento" class="whatsapp-icon" aria-label="Fale conosco no WhatsApp">
    <img src="./images/Logos/whatsapp.png" alt="WhatsApp" />
</a>
     
    </div>
  )
}

export default WhatsApp
import { useEffect } from "react";

export default function Carousel_Services() {
  useEffect(() => {

    if (window.Swiper) {
      new window.Swiper(".swiper-1", {
        spaceBetween: 30,
        slidesPerView: 1,
        simulateTouch: true,
        navigation: {
          nextEl: ".swiper-button-next-1",
          prevEl: ".swiper-button-prev-1",
        },
        pagination: { el: ".swiper-pagination-1", clickable: true },
        loop: true,
        breakpoints: {
            768: { 
              slidesPerView: 2, 
              spaceBetween: 20,
              pagination: false,
            },
            1024: { 
              slidesPerView: 3, 
              spaceBetween: 30,
              pagination: false,
            },
            1282: { 
                slidesPerView: 4, 
                spaceBetween: 30,
                pagination: false,
              },
              1600: { 
                slidesPerView: 4, 
                spaceBetween: 30,
                pagination: false,
              }
          }
      });
    }
}, []);

  return (
    <>
    <div class="swiper swiper-1 mySwiper">
    <div class="swiper-wrapper"> 
      <div class="swiper-slide">
      <div className="card_services">
        <div className="image_card_service">
            <img className="img_card" src="./images/Bem Casados/bemcasado13 1.png" alt="bem casado serviços" />
        </div>
        <div className="content_card_service">
            <h3 className="title_card_service">Bem Casados</h3>
            <p className="description_card_service">O bem-casado é um doce tradicional que simboliza amor e união. Composto por duas camadas macias de pão de ló e um recheio delicado, ele representa a doçura da vida a dois. Uma lembrança sofisticada e cheia de significado para encantar seus convidados.</p>
            {/* <a href="" className="btn">Saiba Mais</a> */}
        </div>
      </div>
      </div>

      <div class="swiper-slide">
      <div className="card_services">
        <div className="image_card_service">
            <img className="img_card" src="./images/Bem Casados/bemnascido1 1.png" alt="bem nascido serviços" />
        </div>
        <div className="content_card_service">
            <h3 className="title_card_service">Bem Nascidos</h3>
            <p className="description_card_service">O Bem-Nascido é um doce delicado que simboliza a alegria e as bênçãos da chegada de um bebê. Feito com massa macia e recheio suave, representa prosperidade e proteção. Oferecido como lembrança, encanta familiares e amigos com seu sabor e significado especial.</p>
            {/* <a href="" className="btn">Saiba Mais</a> */}
        </div>
      </div>
      </div>

      <div class="swiper-slide">
      <div className="card_services">
        <div className="image_card_service">
            <img className="img_card" src="./images/Bem Casados/bemcasado3 1.png" alt="bem vivido serviços" />
        </div>
        <div className="content_card_service">
            <h3 className="title_card_service">Bem Vividos</h3>
            <p className="description_card_service">O Bem-Vivido celebra a alegria de cada momento especial. Com sua massa macia e recheio delicado, simboliza gratidão e felicidade. Perfeito para aniversários, é uma lembrança sofisticada que encanta convidados e torna cada celebração ainda mais doce e inesquecível.</p>
            {/* <a href="" className="btn">Saiba Mais</a> */}
        </div>
      </div>
      </div>

      <div class="swiper-slide">
      <div className="card_services">
        <div className="image_card_service">
            <img className="img_card" src="./images/Bem Casados/bemcasado11 1.png" alt="bem batizado serviços" />
        </div>
        <div className="content_card_service">
            <h3 className="title_card_service">Bem Batizados</h3>
            <p className="description_card_service">O Bem-Batizado é um doce que simboliza pureza e bênçãos na vida do bebê. Com massa macia e recheio delicado, é oferecido como lembrança desse momento especial. Encanta familiares e amigos com seu sabor doce e significado único, tornando o batismo ainda mais inesquecível.</p>
            {/* <a href="" className="btn">Saiba Mais</a> */}
        </div>
      </div>
      </div>
      {/* <div class="swiper-slide">
      <div className="card_services">
        <div className="image_card_service">
            <img className="img_card" src="./images/Bem Casados/bemcasado11.jpeg" alt="bem casado serviços" />
        </div>
        <div className="content_card_service">
            <h3 className="title_card_service">Bem Casados</h3>
            <p className="description_card_service">Os melhores</p>
            <a href="" className="btn">Saiba Mais</a>
        </div>
      </div>
      </div>

      <div class="swiper-slide">
      <div className="card_services">
        <div className="image_card_service">
            <img className="img_card" src="./images/Bem Casados/bemcasado11.jpeg" alt="bem casado serviços" />
        </div>
        <div className="content_card_service">
            <h3 className="title_card_service">Bem Casados</h3>
            <p className="description_card_service">Os melhores</p>
            <a href="" className="btn">Saiba Mais</a>
        </div>
      </div>
      </div> */}
    </div>
    <div className="control_nav">
    <div class="swiper-button-next swiper-button-next-1"></div>
    <div class="swiper-button-prev swiper-button-prev-1"></div>
    </div>
    <div class="swiper-pagination swiper-pagination-1"></div>
    
  </div>

    </>
  );
}

import React from 'react'

function Captar() {
  return (
    <div className='passe'>

    <main className='main_cap'>
        <img src="./images/Logos/logo.png" alt="Lilica Patisserie" />
    <h1>Solicitação de Orçamento</h1>
        <p className='p_cap'>Para solicitar um orçamento, envie-nos uma mensagem pelo WhatsApp.</p>

        <h2 className='h2_cap'>Informações necessárias:</h2>
        <ul className='ul_cap'>
            <li>👤 Nome</li>
            <li>📅 Data do evento</li>
            <li>🎂 Quantidade de bem-casados</li>
            <li>💳 Forma de pagamento (Cartão, Pix, Dinheiro)</li>
        </ul>

        <section class="degustacao">
            <h2 className='h2_cap'>Degustação Grátis</h2>
            <p className='p_cap'>Todos os sábados, das <strong>10h às 17h</strong>.</p>
            <p className='p_cap'>Amostra com <strong>4 unidades de bem-casado tradicional</strong>.</p>
            <p className='p_cap'>Retirada no local ou via motoboy/Uber.</p>
        </section>

        <a href="https://wa.me/5511961206560?text=Olá,%20vim%20através%20do%20Google."
           class="btn-whatsapp_cap">Solicitar pelo WhatsApp</a>
    </main>
     
    </div>
  )
}

export default Captar
import { useState } from "react";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <header>
        <a href="/"><img className="logo" src="./images/Logos/logo.png" alt="Logo Lilica" /></a>
       
        <nav>
          <div className="menu-toggle" onClick={() => setMenuOpen(true)}>
            <i className="fa-solid fa-bars"></i>
          </div>
          <ul className="desktop-menu">
            <li className="active"><a href="/">Início</a></li>
            <li><a href="#servicos" onClick={() => setMenuOpen(false)}>Serviços</a>
            {/* <ul class="submenu">
                <li><a href="#">Bem Casados</a></li>
                <li><a href="#">Bolos</a></li>
            </ul> */}
            </li>
            <li><a href="#sobre">Sobre Nós</a></li>
            <li><a href="#chame">Fale Conosco</a></li>
            <a href="https://www.instagram.com/lilica.patisserie/" target="_blank" className="social"><i className="fa-brands fa-instagram"></i></a>
          </ul>
        </nav>
      </header>
      
      {/* Menu Mobile */}
      <div className={`mobile-menu ${menuOpen ? 'active' : ''}`}>
        <div className="close-menu" onClick={() => setMenuOpen(false)}>
          <i className="fa-solid fa-times"></i>
        </div>
        <ul>
          <li className="active"><a href="#" onClick={() => setMenuOpen(false)}>Início</a></li>
          <li><a href="#servicos" onClick={() => setMenuOpen(false)}>Serviços</a>
          {/* <ul class="submenu_mobile">
                <li><a href="#" onClick={() => setMenuOpen(false)}>Bem Casados</a></li>
                <li><a href="#">Bolos</a></li>
            </ul> */}
          </li>
          <li><a href="#sobre" onClick={() => setMenuOpen(false)}>Sobre Nós</a></li>
          <li><a href="#chame" onClick={() => setMenuOpen(false)}>Fale Conosco</a></li>
          <a href="https://www.instagram.com/lilica.patisserie/" target="_blank" className="social"><i className="fa-brands fa-instagram"></i></a>
        </ul>
      </div>
    </>
  );
}

import React from 'react'

function Footer() {
  return (
    <div>
<div id='chame' class="contato">
    <div class="info">
        <h2>FALE CONOSCO</h2>
        <p>+55 11 96120.6560</p>
        <p>Lilica Patisserie, R. Marcos Frankenthal, 151 - Chácara Inglesa, São Paulo - SP, 05141-100</p>
        <div class="social-icons">
            <a href="https://www.instagram.com/lilica.patisserie/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
            <a href="https://www.lilicapatisserie.com.br/solicitar-orcamento" ><img className='wtsbtn' src="./images/Logos/whatsapp_btn.png" alt="whatsapp" /></a>
        </div>
        <a rel='nofollow' href='https://www.casamentos.com.br/doces-casamento/bem-casados-sp--e108854' title='casamentos.com.br'><img alt='casamentos.com.br' src='https://www.casamentos.com.br/images/sellos/partner--gg453906.png' className='csmcom' /></a>
    </div>
    <div class="mapa">
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d58546.405633556504!2d-46.771557940874956!3d-23.49109338493444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x94ce556c1b042fab%3A0xdf61265305382a60!2sR.%20Marcos%20Frankenthal%2C%20151%20-%20Ch%C3%A1cara%20Inglesa%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005141-100!3m2!1d-23.4910988!2d-46.7303584!5e0!3m2!1spt-BR!2sbr!4v1740947509815!5m2!1spt-BR!2sbr" 
            allowfullscreen="" 
            loading="lazy">
        </iframe>
    </div>
</div>
{/* Direitos Autorais */}
<div className="autor_reserva">
  <p>
Lilica Patisserie ® Todos os Direitos Reservados
  </p>
</div>
     
    </div>
  )
}

export default Footer